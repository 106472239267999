import { Elements } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import PaymentSetupForm from './PaymentSetupForm';
import "./styles.css";

import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_live_vtzew6g2YtndmCWulWDHRiQJ');

export default function App() {

	const [secret, setSecret] = useState(null)
	const [loading, setLoading] = useState(false)
	const [loadingCheckout, setLoadingCheckout] = useState(false)
	const [appPayload, setPayload] = useState({})
	const [display, setDisplay] = useState("prices")

	const [CGVAccepted, setCGVAccepted] = useState(false)

	const queryString = window.location.search;
	console.log(queryString);
	const urlParams = new URLSearchParams(queryString);

	let payload = urlParams.get('payload') || null
	const parsedPayload = JSON.parse(payload || "{}")

	const key = urlParams.get('key')

	// useEffect(() => { setPayload(parsedPayload) }, [])



	useEffect(() => {


		// if payload in url params -> setPayload
		if(payload) {
			setPayload(parsedPayload)
		}

		if(key) {
			setLoading(true)
			fetch('/payload/' + key, {
				headers: { 'Content-Type': 'application/json' },
				method: 'GET'
			}).then(async res => {
				const _payload = await res.json()
				console.log({_payload})
				setPayload(_payload)

				console.log("fetchd payload", _payload)

				payload = JSON.stringify(_payload)

				setLoading(false)

			})
		}

	}, [])

	if (appPayload && appPayload.iban) {
		return <table
			className="email-wrapper"
			width="100%"
			height="100%"
			cellpadding="0"
			cellspacing="0"
			role="presentation"
		>
			<tr>
				<td align="center">
					<table
						className="email-content"
						width="100%"
						cellpadding="0"
						cellspacing="0"
						role="presentation"
					>
						<tr>
							<td className="email-masthead">
								<div
									className="site-header__brand"
									style={{ transform: "translate(0px, 0rem)", textDecoration: "none" }}
								>
									<figure>
										<svg
											x="0px"
											y="0px"
											width="42.6px"
											height="76.1px"
											viewBox="0 0 42.6 76.1"
											style={{ overflow: "visible", enableBackground: "new 0 0 42.6 76.1" }}
											xmlSpace="preserve"
										>
											<path
												fill="#222222"
												d="M39.6,76.1c-1.7,0-3-1.3-3-3V21.2L21.3,7.1L6,21.2v48.9h21.3V35.6l-6-5.3l-6,5.3v28.6c0,1.7-1.3,3-3,3
				c-1.7,0-3-1.3-3-3V34.3c0-0.9,0.4-1.7,1-2.3l9-7.9c1.1-1,2.8-1,4,0l9,7.9c0.6,0.6,1,1.4,1,2.3v38.8c0,1.7-1.3,3-3,3H3
				c-1.7,0-3-1.3-3-3V19.9c0-0.8,0.3-1.6,1-2.2L19.3,0.8c1.2-1.1,2.9-1.1,4.1,0l18.3,16.9c0.6,0.6,1,1.4,1,2.2v53.2
				C42.6,74.8,41.3,76.1,39.6,76.1z"
											></path>
										</svg>
									</figure>
									<div className="logo">
										<span><span>La maison</span> du coworking</span>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td className="email-body" width="100%" cellpadding="0" cellspacing="0">
								<table className="email-body_inner" align="center" width="570" cellpadding="0" cellspacing="0" role="presentation">
									<tr>
										<td className="content-cell">
											<div className="f-fallback">

												<table
													className="body-action"
													align="center"
													width="100%"
													cellpadding="0"
													cellspacing="0"
													role="presentation"
												>

													<tr><td align="left">
														<Elements stripe={stripePromise}>
															<PaymentSetupForm secret={secret} customer={appPayload.stripeCustomerId} payload={appPayload} bypass_stripe={true} />
														</Elements>
													</td></tr>


												</table>
											</div>
										</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>
				</td>
			</tr>
		</table>
	}










	const handleClick = async (event) => {
		setLoadingCheckout(true)
		// Get Stripe.js instance
		const stripe = await stripePromise;

		// Call your backend to create the Checkout Session
		const response = await fetch('/create-checkout-session', {
			headers: { 'Content-Type': 'application/json' },
			method: 'POST', body: JSON.stringify(appPayload)
		});

		const session = await response.json();

		const result = await stripe.redirectToCheckout({
			sessionId: session.id,
		});

		console.log(result)
		setLoadingCheckout(false)
		if (result.error) {
		}
	};

	const handleSetupIntent = async (event) => {
		setLoading(true)

		// Get Stripe.js instance
		const stripe = await stripePromise;

		// Call your backend to create the Checkout Session
		const response = await fetch('/setup-intent/' + appPayload.customer_id, { method: 'GET' });

		const resp = await response.json();

		console.log({ resp })

		setSecret(resp.clientSecret)
		setDisplay("iban")
		setLoading(false)
	};

	const validateVirement = async () => {
		setLoadingCheckout(true)
		if (appPayload && appPayload.customer_id && appPayload.virement) {
			fetch('/create-virement-sub', {
				headers: { 'Content-Type': 'application/json' },
				method: 'POST', body: JSON.stringify({ customer: appPayload.customer_id, payload: appPayload })
			}).then((r) => {
				console.log(r)
				setLoadingCheckout(false)
				window.location.replace("/success.html");
			})
		}
	}

	const mode = appPayload.mode
	const isSubscription = appPayload.Subscription

	console.log(appPayload)

	const HT = (appPayload.LineItems || []).reduce((a, v) => a + (v.Quantity * v.UnitPrice), 0)
	const reduc = appPayload.couponAmount / 100
	const TVA = .2 * (HT - reduc)

	let startDate, date
	const hasDate = !!(appPayload.LineItems && appPayload.LineItems[0].StartDate)

	if (appPayload.LineItems) {
		startDate = new Date(appPayload.LineItems[0].StartDate)
		date = startDate.getDate() + "/" + (startDate.getMonth() + 1) + "/" + startDate.getFullYear()
	}


	const subline = { margin: 0, color: "#85878E", fontSize: 12 }

	if (!appPayload.mode) {
		return <>
			<table
				className="email-wrapper"
				width="100%"
				height="100%"
				cellpadding="0"
				cellspacing="0"
				role="presentation"
			>
				<tr>
					<td align="center">
						<table
							className="email-content"
							width="100%"
							cellpadding="0"
							cellspacing="0"
							role="presentation"
						>
							<tr>
								<td className="email-masthead">
									<div
										className="site-header__brand"
										style={{ transform: "translate(0px, 0rem)", textDecoration: "none" }}
									>
										<figure>
											<svg
												x="0px"
												y="0px"
												width="42.6px"
												height="76.1px"
												viewBox="0 0 42.6 76.1"
												style={{ overflow: "visible", enableBackground: "new 0 0 42.6 76.1" }}
												xmlSpace="preserve"
											>
												<path
													fill="#222222"
													d="M39.6,76.1c-1.7,0-3-1.3-3-3V21.2L21.3,7.1L6,21.2v48.9h21.3V35.6l-6-5.3l-6,5.3v28.6c0,1.7-1.3,3-3,3
					c-1.7,0-3-1.3-3-3V34.3c0-0.9,0.4-1.7,1-2.3l9-7.9c1.1-1,2.8-1,4,0l9,7.9c0.6,0.6,1,1.4,1,2.3v38.8c0,1.7-1.3,3-3,3H3
					c-1.7,0-3-1.3-3-3V19.9c0-0.8,0.3-1.6,1-2.2L19.3,0.8c1.2-1.1,2.9-1.1,4.1,0l18.3,16.9c0.6,0.6,1,1.4,1,2.2v53.2
					C42.6,74.8,41.3,76.1,39.6,76.1z"
												></path>
											</svg>
										</figure>
										<div className="logo">
											<span><span>La maison</span> du coworking</span>
										</div>
									</div>
								</td>
							</tr>
						</table>
					</td>
				</tr>
			</table>
		</>
	}
	
	return <>
		<table
			className="email-wrapper"
			width="100%"
			height="100%"
			cellpadding="0"
			cellspacing="0"
			role="presentation"
		>
			<tr>
				<td align="center">
					<table
						className="email-content"
						width="100%"
						cellpadding="0"
						cellspacing="0"
						role="presentation"
					>
						<tr>
							<td className="email-masthead">
								<div
									className="site-header__brand"
									style={{ transform: "translate(0px, 0rem)", textDecoration: "none" }}
								>
									<figure>
										<svg
											x="0px"
											y="0px"
											width="42.6px"
											height="76.1px"
											viewBox="0 0 42.6 76.1"
											style={{ overflow: "visible", enableBackground: "new 0 0 42.6 76.1" }}
											xmlSpace="preserve"
										>
											<path
												fill="#222222"
												d="M39.6,76.1c-1.7,0-3-1.3-3-3V21.2L21.3,7.1L6,21.2v48.9h21.3V35.6l-6-5.3l-6,5.3v28.6c0,1.7-1.3,3-3,3
					c-1.7,0-3-1.3-3-3V34.3c0-0.9,0.4-1.7,1-2.3l9-7.9c1.1-1,2.8-1,4,0l9,7.9c0.6,0.6,1,1.4,1,2.3v38.8c0,1.7-1.3,3-3,3H3
					c-1.7,0-3-1.3-3-3V19.9c0-0.8,0.3-1.6,1-2.2L19.3,0.8c1.2-1.1,2.9-1.1,4.1,0l18.3,16.9c0.6,0.6,1,1.4,1,2.2v53.2
					C42.6,74.8,41.3,76.1,39.6,76.1z"
											></path>
										</svg>
									</figure>
									<div className="logo">
										<span><span>La maison</span> du coworking</span>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td className="email-body" width="100%" cellpadding="0" cellspacing="0">
								<table className="email-body_inner" align="center" width="570" cellpadding="0" cellspacing="0" role="presentation">
									<tr>
										<td className="content-cell">
											<div className="f-fallback">

												<table
													className="purchase"
													width="100%"
													cellpadding="0"
													cellspacing="0"
													style={{ backgroundColor: "#f4f4f4", padding: 20, borderRadius: 10 }}
												>

													<tr>
														<td>
															<h3>{appPayload.CompanyName}</h3>
															{appPayload.line1}<br />
															{appPayload.ZipCode} {appPayload.City}
														</td>
														<td>
															<h3 className="align-right">Paiement {appPayload.Subscription ? "mensuel" : "unique"}<br />{hasDate ? `Début: ${date}` : ""}</h3>
														</td>
													</tr>
													<tr>
														<td colspan="2">
															<table
																className="purchase_content"
																width="100%"
																cellpadding="0"
																cellspacing="0"
															>
																<tr>
																	<th className="purchase_heading" align="left">
																		<p className="f-fallback">Description</p>
																	</th>

																	<th className="purchase_heading" align="right">
																		<p className="f-fallback">Montant</p>
																	</th>
																</tr>

																{(appPayload.LineItems || []).map(item => <tr>
																	<td width="80%" >
																		<span class="f-fallback"
																		>{item.Name} x{item.Quantity}</span
																		>
																	</td>
																	<td
																		width="20%"
																		class="align-right"
																	>
																		<span class="f-fallback">{(item.Quantity * item.UnitPrice).toFixed(2)}€</span>
																	</td>
																</tr>)}

																<tr>
																	<td width="80%" className="purchase_footer" valign="middle">
																		<p className="f-fallback purchase_total purchase_total--label" style={subline}>Sous-total HT</p>
																	</td>
																	<td width="20%" className="purchase_footer" valign="middle" >
																		<p className="f-fallback purchase_total" style={subline}>{HT.toFixed(2)}€</p>
																	</td>
																</tr>

																{appPayload.couponId ? <>

																	<tr>
																		<td width="80%" className="purchase_footer" valign="middle">
																			<p className="f-fallback purchase_total purchase_total--label" style={subline}>Réduction</p>
																		</td>
																		<td width="20%" className="purchase_footer" valign="middle" >
																			<p className="f-fallback purchase_total" style={subline}>- {reduc.toFixed(2)}€</p>
																		</td>
																	</tr>

																	<tr>
																		<td width="80%" className="purchase_footer" valign="middle">
																			<p className="f-fallback purchase_total purchase_total--label" style={subline}>Sous-total</p>
																		</td>
																		<td width="20%" className="purchase_footer" valign="middle" >
																			<p className="f-fallback purchase_total" style={subline}>{(HT - reduc).toFixed(2)}€</p>
																		</td>
																	</tr>

																</> : <></>}

																<tr>
																	<td width="80%" className="purchase_footer" valign="middle">
																		<p className="f-fallback purchase_total purchase_total--label" style={subline}>TVA (20%)</p>
																	</td>
																	<td width="20%" className="purchase_footer" valign="middle" >
																		<p className="f-fallback purchase_total" style={subline}>{TVA.toFixed(2)}€</p>
																	</td>
																</tr>

																<tr>
																	<td width="80%" className="purchase_footer" valign="middle">
																		<p className="f-fallback purchase_total purchase_total--label">Total TTC</p>
																	</td>
																	<td width="20%" className="purchase_footer" valign="middle" >
																		<p className="f-fallback purchase_total">{((HT - reduc) + TVA).toFixed(2)}€</p>
																	</td>
																</tr>

															</table>
														</td>
													</tr>
												</table>

												<table
													className="body-action"
													align="center"
													width="100%"
													cellpadding="0"
													cellspacing="0"
													role="presentation"
												>

													<tr>
														<td >
															<input type="checkbox" id="cgv" style={{ display: "inline-block", width: 15, height: 15, float: "left" }} onClick={e => setCGVAccepted(e.target.checked)} />
														</td>
														<td style={{textAlign: "left"}}>
															<label style={{ display: "inline-block", zoom: 1.1, marginTop: 1, textAlign: "left" }} for="cgv" aria-describedby="label">
																J'ai lu et j'accepte les <a href="https://www.lamaisonducoworking.fr/conditions-generales-de-ventes/" target="_blank" rel="noreferrer">conditions générales de vente</a>
															</label>
														</td>
													</tr>
												</table>

												<table
													className="body-action"
													align="center"
													width="100%"
													cellpadding="0"
													cellspacing="0"
													role="presentation"
												>
													{!appPayload.virement ? <>

														{(display === "prices" && isSubscription) && <tr>
															<td align="center">
																<table
																	width="100%"
																	border="0"
																	cellspacing="0"
																	cellpadding="0"
																	role="presentation"
																>
																	<tr>
																		<td align="center" style={{ width: "48%" }}>
																			{CGVAccepted ? <a
																				href="#"
																				onClick={handleClick}
																				className="f-fallback button button--green"
																				style={{ cursor: loadingCheckout ? "not-allowed" : "pointer" }}
																			>
																				{!loadingCheckout ? "Carte bancaire" : <Loader type="Bars" color="#FFFFFF" height={15} width={20} />}
																			</a> : <div className="f-fallback button button--gray" style={{ cursor: "not-allowed" }}>
																				{!loadingCheckout ? "Carte bancaire" : <Loader type="Bars" color="#FFFFFF" height={15} width={20} />}
																			</div>}
																		</td>

																		<td style={{ width: 10 }}></td>

																		<td align="center" style={{ width: "48%" }}>
																			{CGVAccepted ? <a
																				onClick={handleSetupIntent}
																				className="f-fallback button button--green"
																				style={{ cursor: loading ? "not-allowed" : "pointer" }}
																			>
																				{!loading ? "Prélèvement" : <Loader type="Bars" color="#FFFFFF" height={15} width={20} />}
																			</a> : <div className="f-fallback button button--gray" style={{ cursor: "not-allowed" }}>
																				{!loadingCheckout ? "Prélèvement" : <Loader type="Bars" color="#FFFFFF" height={15} width={20} />}
																			</div>}
																		</td>
																	</tr>
																</table>


															</td>

														</tr>}

														{(display === "prices" && !isSubscription) && <tr>
															<td align="center">

																<table
																	width="100%"
																	border="0"
																	cellspacing="0"
																	cellpadding="0"
																	role="presentation"
																>
																	<tr>
																		<td align="center">
																			{CGVAccepted ? <a
																				href="#"
																				onClick={handleClick}
																				className="f-fallback button button--green"
																				style={{ cursor: loadingCheckout ? "not-allowed" : "pointer" }}
																			>
																				{!loadingCheckout ? "Accepter" : <Loader type="Bars" color="#FFFFFF" height={15} width={20} />}

																			</a> : <div className="f-fallback button button--gray" style={{ cursor: "not-allowed" }}>
																				{!loadingCheckout ? "Accepter" : <Loader type="Bars" color="#FFFFFF" height={15} width={20} />}
																			</div>}
																		</td>
																	</tr>
																</table>


															</td>

														</tr>}

														{display === "iban" && <tr><td align="left">
															<Elements stripe={stripePromise}>
																{secret && <PaymentSetupForm secret={secret} customer={appPayload.customer_id} payload={appPayload} />}
															</Elements>
														</td></tr>}

													</> : <>


														<td align="center">

															<table
																width="100%"
																border="0"
																cellspacing="0"
																cellpadding="0"
																role="presentation"
															>
																<tr>
																	<td align="center">
																		{CGVAccepted ? <a
																			href="#"
																			onClick={validateVirement}
																			className="f-fallback button button--green"
																			style={{ cursor: loadingCheckout ? "not-allowed" : "pointer" }}
																		>
																			{!loadingCheckout ? "Confirmer que je paie par virement" : <Loader type="Bars" color="#FFFFFF" height={15} width={20} />}
																		</a> : <div className="f-fallback button button--gray" style={{ cursor: "not-allowed" }}>
																			{!loadingCheckout ? "Confirmer que je paie par virement" : <Loader type="Bars" color="#FFFFFF" height={15} width={20} />}
																		</div>}
																	</td>
																</tr>
															</table></td>


													</>}

												</table>
											</div>
										</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>
				</td>
			</tr>
		</table ></>
}