import React, { useState } from 'react';
import { useStripe, useElements, IbanElement } from '@stripe/react-stripe-js';

import IbanForm from './IbanForm';

export default function PaymentSetupForm({ secret, customer, payload, bypass_stripe = false }) {
	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		console.log("handle submit")

		if (!stripe || !elements) {
			return;
		}

		const iban = elements.getElement(IbanElement);

		const accountholderName = event.target['accountholder-name'];
		const email = event.target.email;

		const result = await stripe.confirmSepaDebitSetup(secret, {
			payment_method: {
				sepa_debit: iban,
				billing_details: {
					name: accountholderName.value,
					email: email.value,
				},
			}
		});

		if (result.error) {
			// Show error to your customer.
			console.log(result.error.message);
		} else {

			console.log({ result })

			const response = await fetch('/validate-iban-subn', {
				headers: { 'Content-Type': 'application/json' },
				method: 'POST', body: JSON.stringify({ payment_method: result.setupIntent.payment_method, customer, payload })
			});

			const res = await response.json();

			window.location.replace(window.location.origin + "/success.html");
			console.log({ res })
		}
	};

	if (bypass_stripe) {
		return <IbanForm onSubmit={async (event) => {
			event.preventDefault();
			
			const iban = event.target['iban'].value;
			const accountholderName = event.target['accountholder-name'].value;
			const email = event.target.email.value;

			console.log({ iban, accountholderName, email })


			const response = await fetch('/validate-iban-change', {
				headers: { 'Content-Type': 'application/json' },
				method: 'POST', body: JSON.stringify({ 
					iban, accountholderName, email, payload 
				})
			});

			const res = await response.json();

			window.location.replace(window.location.origin + "/success_iban.html");

		}} disabled={!stripe} bypass_stripe={bypass_stripe} />
	}

	return (
		<IbanForm onSubmit={handleSubmit} disabled={!stripe} />
	);
}