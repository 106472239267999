/**
* Use the CSS tab above to style your Element's container.
*/
import React, { useState } from 'react';
import Loader from "react-loader-spinner";
import { IbanElement } from '@stripe/react-stripe-js';
import './IbanFormStyles.css'
import IBAN from './iban_raw'

// Custom styling can be passed as options when creating an Element.
const IBAN_STYLE = {
	base: {
		color: '#32325d',
		fontSize: '16px',
		'::placeholder': {
			color: '#aab7c4'
		},
		':-webkit-autofill': {
			color: '#32325d',
		},
	},
	invalid: {
		color: '#fa755a',
		iconColor: '#fa755a',
		':-webkit-autofill': {
			color: '#fa755a',
		},
	}
};

const IBAN_ELEMENT_OPTIONS = {
	supportedCountries: ['SEPA'],
	placeholderCountry: 'FR',
	style: IBAN_STYLE,
};

export default function IbanForm({ onSubmit, disabled, bypass_stripe = false }) {

	const [loading, setLoading] = useState(false)

	return (<>
		<form onSubmit={onSubmit}>
			<div style={{ backgroundColor: "#f4f4f4", padding: 20, borderRadius: 10 }}>
				<div className="form-row inline">
					<div className="col">
						<label>Name<br /><input name="accountholder-name" placeholder="Jenny Rosen" required /></label>
					</div>
					<br />

					<div className="col">
						<label>Email Address<br /><input name="email" type="email" placeholder="jenny.rosen@example.com" required /></label>
					</div>
				</div>
				<br />

				<div className="form-row">
					<label>
						IBAN<br />
						{!bypass_stripe ?
							<IbanElement id="iban" onChange={e => console.log(e.target, e, e.value)} options={IBAN_ELEMENT_OPTIONS} />
							:
							<IBAN
								render={({ onChange, value, iban, ...rest }) => (
									<React.Fragment>
										<input {...rest} id="iban" onChange={onChange} value={value} />
									</React.Fragment>
								)}
							/>
						}
					</label>
				</div>

				{/* <button type="submit" disabled={disabled}>
					Set up SEPA Direct Debit
      </button> */}
				{/* Display mandate acceptance text. */}
				<div className="mandate-acceptance">
					En signant ce formulaire de mandat, vous autorisez La Maison du Coworking (LMDC SAS) à envoyer des instructions à votre banque pour débiter votre compte, et votre banque à débiter votre compte conformément aux instructions de La Maison du Coworking.
				</div>
			</div>

			<br />

			<table
				width="100%"
				border="0"
				cellspacing="0"
				cellpadding="0"
				role="presentation"
			>
				<tr>
					<td align="center" style={{ width: "100%" }}>
						<button
							type="submit"
							className="button button--green"
							disabled={disabled}
							style={{ cursor: loading ? "not-allowed" : "pointer" }}
							onClick={() => { setLoading(true) }}
						>{!loading ? "Valider" : <Loader type="Bars" color="#FFFFFF" height={15} width={20} />}</button>
					</td>
				</tr>
			</table>
		</form>
	</>
	);
};